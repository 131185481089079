import React from "react";
import Screenshot from "./Screen";
import TrackedLink from "../../Analytics/TrackedLink";
import Container from "../../common/UI/Container";
import "./index.scss";

const Idea = () => {
  return (
    <section className="PgEM-I">
      <Container mode="standard">
        <h2 className="PgEM-I__title">
          Get ideas for booming and ready-to-launch products for Amazon or
          TikTok Shop
        </h2>
        <div className="PgEM-I__content">
          <div className="PgEM-I__info">
            <p className="PgEM-I__boldText">
              Analysts from Sellerhook (which is part of the AMZScout family)
              are here to help you find potential bestsellers to sell, and
              follow any other criteria you have in mind. As a result, you can
              avoid investing in products that are declining in sales,{" "}
              <span>obtain up to 90% of sales</span> in new niches, and crush
              your competitors.
            </p>
            <p className="PgEM-I__text">
              Order product research for your specific business model and
              product range via a one-time payment, or through installments of
              four interest-free payments.
            </p>
            <TrackedLink
              classes="PgEM-I__link"
              category="main_page"
              action="order your product research"
              path="https://sellerhook.com/?utm_source=site&utm_medium=amzscout&utm_campaign=main_text"
              target
            >
              ORDER YOUR PRODUCT RESEARCH
            </TrackedLink>
          </div>
          <Screenshot />
        </div>
      </Container>
    </section>
  );
};

export default Idea;
