import React from "react";
import PropTypes from "prop-types";
import SVG_LOGO from "../../../assets/images/vector/components/common/ProAiBanner/logo.svg";
import "./index.scss";

const ProAiBanner = ({ clickHandler, proAiBannerRef }) => {
  return (
    <div className="GC-PAB" ref={proAiBannerRef}>
      <div className="GC-PAB-content" onClick={clickHandler}>
        <div className="GC-PAB-block">
          <img className="GC-PAB__logo" src={SVG_LOGO} alt="" />
          <div className="GC-PAB-title">
            <p className="GC-PAB-title__text">
              <span>UNLOCK THE POWER OF AI</span> WITH THE MOST USER-FRIENDLY
              PRODUCT RESEARCH TOOL*
            </p>
            <p className="GC-PAB-title__reference">
              *According to a survey of Amazon sellers community
            </p>
          </div>
        </div>
        <button className="GC-PAB__cta">TRY THE PRO AI EXTENSION</button>
      </div>
    </div>
  );
};
ProAiBanner.propTypes = {
  clickHandler: PropTypes.func,
};
export default ProAiBanner;
