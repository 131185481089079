import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import track from "../../tracking/analytics";
import { trackGa } from "../../tracking/googleAnalytics";
import ProAiBanner from "../../components/common/ProAiBanner";

const ProAiBannerContainer = (props) => {
  const {
    category = "Index",
    setIsDealsHides,
    setIsShowProAiBannerBottom,
  } = props;
  const proAiBannerRef = useRef(null);
  const clickHandler = () => {
    const action = "open_banner";
    const label = "pro_ai";
    track(category, action, label);
    trackGa(action, { category, label });
    window.open(
      `${process.env.SCOUT_EXT_PRO_NEW}?utm_source=site&utm_medium=amzscout&utm_campaign=main_banner`,
      "_blank"
    );
  };

  const scrollHandler = () => {
    const innerHeight = window.innerHeight;
    const banner = proAiBannerRef.current.getBoundingClientRect();
    if (setIsDealsHides) {
      const top = innerHeight - banner.top > 0;
      const bottom = innerHeight - banner.bottom < 60;
      setIsDealsHides(top && bottom);
    }
    if (setIsShowProAiBannerBottom) {
      setIsShowProAiBannerBottom(banner.top < innerHeight);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", scrollHandler);
    window.addEventListener("resize", scrollHandler);
    return () => {
      window.removeEventListener("resize", scrollHandler);
      window.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  return (
    <ProAiBanner clickHandler={clickHandler} proAiBannerRef={proAiBannerRef} />
  );
};

ProAiBannerContainer.propTypes = {
  category: PropTypes.string,
};

export default ProAiBannerContainer;
