import React from "react";
import PNG from "../../../../assets/images/raster/pages/main/Idea/Idea@2x.png";
import PNG_1200 from "../../../../assets/images/raster/pages/main/Idea/Idea@1200_2x.png";
import PNG_992 from "../../../../assets/images/raster/pages/main/Idea/Idea@992_2x.png";
import PNG_mob from "../../../../assets/images/raster/pages/main/Idea/Idea@mob_2x.png";
import WEBP from "../../../../assets/images/raster/pages/main/Idea/Idea@2x.webp";
import WEBP_1200 from "../../../../assets/images/raster/pages/main/Idea/Idea@1200_2x.webp";
import WEBP_992 from "../../../../assets/images/raster/pages/main/Idea/Idea@992_2x.webp";
import WEBP_mob from "../../../../assets/images/raster/pages/main/Idea/Idea@mob_2x.webp";
import "./index.scss";

const Screen = () => {
  return (
    <picture>
      <source type="image/webp" media="(min-width: 1365px)" srcSet={WEBP} />
      <source media="(min-width: 1365px)" srcSet={PNG} />
      <source
        type="image/webp"
        media="(min-width: 1200px) and (max-width: 1365px)"
        srcSet={PNG_1200}
      />
      <source
        media="(min-width: 1200px) and (max-width: 1365px)"
        srcSet={WEBP_1200}
      />
      <source
        type="image/webp"
        media="(min-width: 992px) and (max-width: 1199px)"
        srcSet={WEBP_992}
      />
      <source
        media="(min-width: 992px) and (max-width: 1199px)"
        srcSet={PNG_992}
      />
      <source
        type="image/webp"
        media="(min-width: 768px) and (max-width: 991px)"
        srcSet={WEBP}
      />
      <source media="(min-width: 768px) and (max-width: 991px)" srcSet={PNG} />
      <source type="image/webp" media="(max-width: 767px)" srcSet={WEBP_mob} />
      <source media="(max-width: 767px)" srcSet={PNG_mob} />
      <img className="PgEM-I-S" src={PNG} alt="" loading="lazy" />
    </picture>
  );
};

export default Screen;
