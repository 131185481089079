import React from "react";
import SVG_INCOMPLETE_STARS from "../../../../assets/images/vector/pages/main/RatingOverview/incomplete_stars.svg";
import "./index.scss";

const ChromeStore = () => (
  <div className="PgEM-RO-CS">
    <p className="PgEM-RO-CS-title">
      <span>‘Excellent’</span>
      <img src={SVG_INCOMPLETE_STARS} alt="*****" />
    </p>
    <p className="PgEM-RO-CS-text">
      Over <b>1,000</b> reviews on <b>Chrome Store</b>
    </p>
  </div>
);

export default ChromeStore;
